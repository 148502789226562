import { useTranslation } from 'next-i18next'
// @ts-expect-error -- FIX swiper types error
import { Autoplay } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
import safelyParseJson from '@/libs/utils/safely-parse-json'
import { Slide } from './types'
import HeroVideo from './hero-video'

const Slides = () => {
  const { t } = useTranslation()
  const slides = safelyParseJson<Slide[]>(t('crowdin:home-page.hero-section.slides')) || []
  const wrapperClass = [
    '[&>.swiper-slide]:pl-[24px] mobileUp:[&>.swiper-slide]:pl-[30px]',
    '[&>.swiper-slide:first-of-type]:pl-[37px] [&>.swiper-slide:last-of-type]:pr-[37px]',
    'mobileUp:[&>.swiper-slide:first-of-type]:pl-[50px] mobileUp:[&>.swiper-slide:last-of-type]:pr-[50px]',
  ].join(' ')
  return (
    <div className={`mt-[30px] w-full`}>
      <Swiper
        cssMode
        watchSlidesProgress={true}
        direction='horizontal'
        slidesPerView='auto'
        grabCursor
        draggable={true}
        simulateTouch={true}
        modules={[Autoplay]}
        pagination={{
          clickable: true,
        }}
        wrapperClass={wrapperClass}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {slides.map((slide: Slide) => {
          return (
            <SwiperSlide key={slide.alt} style={{ width: 'auto' }}>
              {({ isVisible }) => {
                return <HeroVideo slide={slide} isPlaying={isVisible} />
              }}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Slides
